import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import isBrowser from 'components/lib/isbrowser'
import { useTranslation } from 'react-i18next'
import Banner from 'components/banner';
import Layout from 'components/layout';
import Content from 'components/content/documentation/user-guide';
import './docs.scss';
import { LeftNav } from 'components/business-components';
import Faq from 'components/business-components/faq';
import userGuides from 'components/content/public-pages/docs/user-guide';

const UserGuide = ({ data }) => {
  const { t } = useTranslation()
  // const content = data?.pages?.edges?.[0]?.node;

  const content ={
    title: t('staticPage.userGuide.welcomeToDocs'),
    description: t('staticPage.userGuide.description'),
    className: 'getStartedImage'
  }

  const faqActive = isBrowser && window?.location?.pathname === '/doc/user-guide';

  if (!content) {
    return null;
  }

  return (
    <Layout>
      <Helmet title={content.title} />
      <div className="docs">
        <div className="left">
          <LeftNav
            navData={[
              {
                label: t('common.home'),
                icon: 'fa fa-home',
                url: '/doc/user-guide'
              },
              {
                label: t('pages.documentation.userGuides'),
                icon: 'fa fa-list-alt',
                subItems: [
                  {
                    label: t('pages.documentation.gettingStarted'),
                    url: '/doc/developer-onboarding'
                  },
                  {
                    label: t('pages.documentation.userManagement'),
                    url: '/doc/user-management'
                  },
                  {
                    label: t('pages.documentation.aspspClientManagement'),
                    url: '/doc/client-management'
                  },
                  {
                    label: t('pages.documentation.softwareStatement'),
                    url: '/doc/software-statement',
                    protected: true
                  },
                  {
                    label: t('pages.documentation.aispFlow'),
                    url: '/doc/aisp-flow'
                  },
                  {
                    label: t('pages.documentation.pispFlow'),
                    url: '/doc/pisp-flow'
                  },
                  {
                    label: t('pages.documentation.testingGuides'),
                    url: '/doc/postman-flow'
                  }
                ]
              }
            ]}
          />
        </div>
        <div className="right">
          <Banner {...content} />
          <userGuides/>
          {/* <Content {...content} /> */}
          {faqActive && (
            <div className="faqWrapper">
              <Faq />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
};

export default UserGuide;

// export const query = graphql`
// 	query DocsPageQuery ($path: String!) {
//         ...MarkdownPages
//     }
// `